
.mp-pushed > .page-nav {
    overflow: auto;
}

.page-nav {
    @extend .clearfix;
    @extend .mp-menu;

    &__wrapper {
            @extend .clearfix;
    }

    &__level {
        @extend .mp-level;

        &--open {
            @extend .mp-level-open;
        }

        @include media-query(small) {
            list-style: none;
            background: white;

            &--primary {
                > .page-nav__close {
                    float: none;
                    width: 100%;
                    text-align: left;
                }

                > ul > li > a {
                    font-weight: 700;
                    border-bottom: 1px solid $color-blue-50;

                    .icon-chevron-down {
                                    @include visuallyhidden();
                    }
                }
            }

            &:not(.page-nav__level--primary) {
                > ul > li > a,
                > div > ul > li > a,
                > div > div > ul > li > a {
                    padding-left: $bsu;
                }
            }
        }
    }

    &__back {
        @extend .mp-back;
    }
    &__close {
        @extend .mp-close;
    }

    &__toggle {
        display: block;
        padding: $bsu-s;

        color: $color-blue-positive;
        background: $color-blue-decent;

        font-weight: 600;

        @include media-query(lap-and-up) {
            display: none;
        }

        &:hover {
            cursor: pointer;
            color: $color-brand-alt;
        }
    }

    &__list {
            background: #fff;
    }

    @include media-query(lap-and-up) {
        max-width: $container-max;
    }

    @include media-query(lap-and-up) {

        &__back, &__close {
                display: none;
        }

        ul {
                list-style: none;
                padding: 0;
                // margin: 0;
        }

        &__list {
            @include clearfix();

            margin: 0;
            padding: 0 $bsu-s;
            list-style: none;

            display: table;
            width: 100%;

            display: flex;
            flex-wrap: wrap;

            position: relative;
            // z-index: 0;

            float: left;

            background: $color-nav-bg;

            &__item, > li {
                position: relative;
                overflow: hidden;

                display: table-cell;
                width: auto;

                padding: $bsu-s $bsu ($bsu-s - 4px) $bsu;

                @include media-query(lap) {
                    padding: $bsu-s $bsu-s ($bsu-s - 4px);
                }

                @include inuit-font-size(14px);
                font-weight: 600;

        
                &:last-child:after {
                    content: none;
                }

                &--current, &.current_page_item {
                    > a {
                        color: $color-brand-alt;
                    }
                }

                > a {
                    position: relative;
                    z-index: 10;

                    display: block;
                    padding: $bsu-s $bsu ($bsu-s - 4px) $bsu;
                    margin: neg($bsu-s) neg($bsu) neg($bsu-s - 4px) neg($bsu);
                    
                    color: white;

                    @include media-query(lap) {
                        padding: $bsu-s $bsu-s ($bsu-s - 4px);
                        margin: neg($bsu-s) neg($bsu-s) neg($bsu-s - 4px);
                    }

                    border-bottom: 5px solid transparent;

                    > .icon-chevron-down {
                        display: inline-block;

                        width: 20px;
                        height: 20px;

                        position: relative;
                        // top: $bsu-s + 2px;
                        top: $bsu-xs - 2px;
                        left: $bsu-xs - 3px;
                        content: "";

                        transform-origin: 50% 50%;
                        background-size: cover;
                        transition: transform .2s;

                        @include media-query(lap) {
                            width: 14px;
                            height: 14px;
                            top: 2px;
                        }
                    }
                }

                &:hover, &.active, &.current_page_ancestor, &.current-page-ancestor, &.current_page_item {
                        transition: all .2s ease-in;

                        color: $color-nav-text-active;
                        background: white;
                        box-shadow: 1px 0 2px $color-blue-gray, 0px -0.5px 0px white;
                        
                        > a {
                            color: $color-nav-text-active;
                            color: inherit;
                            border-bottom-color: white;
                            transition: border-bottom-color .2s ease-out .2s;
                        }
                }

                &:hover {
                    > a {
                        color: $color-nav-text-active;
                    }

                    &:before {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;

                        content: "";
                        display: block;
                        border-bottom: 2px solid #fff;

                        z-index: 9999;
                    }
                }

                > .sub-menu {
                    position: absolute;
                    top: 100%;
                    left: -9999px;
                    z-index: 999;
                    // min-width: 150px;
                    min-width: 100%;
                    width: auto;
                    background: #fff;
                    box-shadow: 1px 1px 2px $color-blue-gray;

                    .sub-menu {
                        margin-left: $bsu-s;
                    }
                    // line-height: 1.4;
                    // padding: $bsu-xs;

                    &:before {
                        content: "";
                        position: relative;
                        display: block;
                        border-top: 2px solid #fff;
                    }

                    li {

                        > a {
                            border-bottom: 1px solid rgba($color-blue-gray, .5);
                            padding: $bsu-xs $bsu-s;
                            display: block;

                            &:hover {
                                background: rgba(#5F95C4, .3);
                            }
                        }

                        &:last-child {
                            border-bottom-width: 0;
                        }
                    }
                }
                .sub-menu {
                    margin-bottom: 0;
                }

                &:hover {
                    overflow: visible;

                    > .sub-menu {
                        left: 0;
                    }
                }
            }
        }

    }
}


.page-subnavigation {
  a {
    &:hover {
        color: $color-brand;
    }
  }

 > li {
    .current_page_item,
    &.current_page_item,
    &.current-menu-item,
    .current-menu-item,
    &.current-post-ancestor,
    &.current_page_ancestor,
    .current_page_ancestor,
    .current-post-parent {
        @include media-query(lap-and-up) {
            > a {
                color: $color-brand;
                // @include minus-sign();
            }
        }
    }
    &.current_page_parent, &.current-menu-parent {
        @include media-query(lap-and-up) {
            > a {
                color: $color-brand;
            }
        }
    }
  }

}
