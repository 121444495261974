
.tabs {
    // @extend .clearfix;
    position: relative;

    &__items {
        font-size: 0;
    }

    &__controls {
        @extend .clearfix;
        margin-bottom: $bsu-s;

        > .tabs__item__action {
            float: left;

        }
        @include media-query(lap-and-up) {
            margin-bottom: 0;

            > .tabs__item__action {
                float: none;
            }
        }
    }

    &__item {
        display: inline;

        &__action {
            position: relative;

            width: 49.8% !important;
            margin-bottom: 0.4%;

            display: inline-block;
            padding: $bsu-s;
            // margin-bottom: 1px;

            @include inuit-font-size($inuit-base-font-size);
            height: auto;

            color: $color-brand;
            background: $color-blue-negative;
            border: 1px solid $color-blue-negative;

            vertical-align: bottom;

            cursor: pointer;

            transition: background-color .2s;

            .no-touchevents &:hover {
                background-color: transparent;
            }

            &:nth-child(odd) {
                margin-right: 0.2%;
            }
            &:nth-child(even) {
                margin-left: 0.2%;
            }

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;


            @include media-query(lap-and-up) {
                padding-left: $bsu-s;
                padding-right: $bsu-s;

                min-width: 20%;
                width: auto !important;

                &:nth-child(odd),
                &:nth-child(even) {
                   margin: 0;
                   margin-right: $bsu-xs;
                }
                &:last-child {
                    margin-right: 0;
                }


                .no-touchevents &:hover {
                   border-bottom-color: transparent;

                    &:after {
                        content: "";

                        position: absolute;
                        left: 0;
                        right: 0;
                        background-color: #fff;
                        bottom: -2px;
                        height: 2px;

                        z-index: 1;
                    }
                }
            }


        }

        &--active {
            background-color: transparent;
            font-weight: 600;

            overflow: visible;
            position: relative;

            @include media-query(lap-and-up) {
                border-bottom-color: #fff;

                &:after {
                    content: "";

                    position: absolute;
                    left: 0;
                    right: 0;
                    background-color: #fff;
                    bottom: -2px;
                    height: 2px;

                    z-index: 1;
                }
            }

        }

        &__panel {
            display: inline;
            display: inline-block;

            overflow: hidden;
            position: relative;
            width: 0;height: 0;

            opacity: 0;
            transition: opacity .3s;

            &--active {
                display: inline;
                opacity: 1;

                .tabs__item__content {
                    opacity: 1;
                }

            }
        }

        &__content {
            @extend .box;
            @extend .box--small;

            display: block;
            float: left;
            width: 100%;

            margin-top: -1px;
            border: 1px solid $color-blue-negative;

            opacity: 0;
            transition: opacity .3s;

            @include inuit-font-size($inuit-base-font-size);

        }
    }

}
