/**

**/

    .page-subnavigation {
        @extend .list-bare;
        opacity: 1;

        @include media-query(lap-and-up) {
            margin-left: $bsu-s;

            @include inuit-font-size(13px, 1.8);

            &--small {
                @include inuit-font-size(13px, 1.8);
            }

            a {
                display: block;
                transition: .1s color ease-in-out;
                // line-height: 1.8;

                &:hover {
                    color: $color-brand-alt;
                }
            }

        li {
            // &:not(:last-child) {
                > a {
                    margin-bottom: $bsu-xs;
                }
            // }
            &.page_item_has_children, &.menu-item-has-children {
                > a {
                    @include plus-sign();
                }
                > a:after {
                    color: $color-blue-decent;
                }
            }
        }
        > li {

            .current_page_item,
            &.current_page_item,
            &.current-menu-item,
            .current-menu-item,
            &.current-post-ancestor,
            &.current_page_ancestor,
            .current_page_ancestor,
            .current-post-parent,
            &.current-cat-ancestor,
            &.current-cat-parent,
            .current-cat {
                @include media-query(lap-and-up) {
                    > a {
                        color: $color-brand-alt;
                        // @include minus-sign();
                    }

                    > .children, > .sub-menu {
                        display: block;
                    }

                    &.page_item_has_children, &.menu-item-has-children, {
                        > a {
                            @include minus-sign();
                        }
                    }
                }
            }
            &.current_page_parent, &.current-menu-parent, &.current-cat {
                @include media-query(lap-and-up) {
                    > a {
                        color: $color-brand-alt;
                        @include minus-sign();
                    }

                    > .children, > .sub-menu {
                        display: block;
                    }
                }
            }


            .children, .sub-menu {
                display: none;
            }

            position: relative;
            font-weight: 600;

            .children, .sub-menu {
                margin: 0;
                padding: 0;
                list-style: none;

                margin-left: $bsu-xs;

                > li {
                    font-weight: 400;
                }



                > ul {
                    margin-left: $bsu;
                }
            }
        }
        }

        &--inline {
            margin: 0 0 $bsu;

            background: $color-blue-negative;
            text-align: center;

            @include inuit-font-size($inuit-base-font-size, false);

            li {

                > a {
                    margin: -$bsu-s;
                    padding: $bsu-s;
                }
            }

            .page_item_has_children > a {
                &:after {
                    content: none !important;
                }
            }

            .children {
                display: none !important;
            }
        }
    }
