

h1, h2, .beta, .entry-title {

  &, > a {
    color: $color-headings;
    // color: inherit;
  }
}


.entry-title, h1, h2 {
  &:before {
    display: inline-block;
    content: "//";
    color: #5F95C4;
    margin-right: $bsu-s;
  }
}
