@import "trumps.icons";

html, body {
    min-height: 100%;
    font-family: "Open Sans", sans-serif;
    @include inuit-font-size($base-font-size);
}

body {
    // -ms-word-break: break-all;

    /* Non standard for webkit */
    // word-break: break-word;

    @include media-query(small) {
        // word-break: break-word;
        hyphens: auto;
    }
}

html {
    background-image: linear-gradient(180deg, darken($color-blue-bright, 30), $color-blue-bright 1000px, $color-blue-bright 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#004d7d', endColorstr='#dee5ec');/*For IE7-8-9*/
}

html.blank-page {
    background: white;
}


a { //, a:link
    text-decoration: none;

    color: $color-brand;
    transition: .1s color;
    cursor: pointer;

    .no-touchevents &:hover {
        color: $color-brand-alt;
    }

    &[target="_blank"] {
        &:after {
            content: "";
            color: $color-blue-20;

            @extend .icon;
            @extend .icon--small;

            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M18.277%206.32c-.43-.43-1.126-.43-1.556%200L15%208.044l-.308-.308c-1.168-1.168-3.216-1.168-4.384%200l-4.172%204.172c-.584.584-.906%201.363-.906%202.192s.322%201.607.906%202.19l.308.31-1.722%201.72c-.43.43-.43%201.127%200%201.557.215.215.496.322.778.322s.563-.108.778-.323L8%2018.155l.308.308c.584.584%201.362.906%202.192.906s1.608-.323%202.192-.907l4.172-4.172c.584-.583.906-1.36.906-2.19s-.322-1.61-.906-2.193l-.308-.308%201.722-1.723c.43-.43.43-1.126%200-1.556zm-2.97%206.415l-4.17%204.172c-.17.168-.403.253-.637.253s-.468-.084-.636-.253l-.308-.308.722-.723c.43-.43.43-1.126%200-1.556-.215-.214-.496-.32-.778-.32s-.563.106-.778.32L8%2015.044l-.308-.308c-.168-.168-.26-.395-.26-.636s.092-.47.26-.637l4.172-4.172c.168-.167.394-.26.636-.26s.468.093.636.26l.308.31-.722.72c-.43.43-.43%201.127%200%201.557.215.215.496.322.778.322s.563-.108.778-.323l.722-.722.308.308c.168.168.26.395.26.636s-.092.467-.26.635z%22%20fill%3D%22%237fa6be%22%2F%3E%3C%2Fsvg%3E');
            background-size: cover;
            margin-left: 2px;
            // width: $bsu-s;
            // height: $bsu-s;

        }
        &.no-icon:after {
            content: none;
        }
    }

    &:active {
        outline: 0;
    }

    &:focus-visible {
        outline: 2px dashed $color-brand;
        outline-offset: 3px;
    }
}

ul {
    list-style-position: inside;
    margin: 0 0 $bsu;

    &.list--outside {
        margin-left: $bsu;
        list-style-position: outside;
        > li { margin-bottom: $bsu-xs; }
    }
}

ol {
    margin-left: $bsu;
}

.page-wrapper {
    margin: 0 auto;
    max-width: $container-max;
}

.container-wrapper {

    @include media-query(desk) {

        position: relative;

        &:before, &:after {
            content: "";

            position: absolute;
            top: $bsu-l;

            height: 85%;
            width: 40px;

            z-index: -1;

            border-radius: 100%;
            box-shadow: 0 0 20px rgba(0,0,0,0.4);
        }

        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    }
}

// ul, dl {
//     @extend %list-bare;
// }
// [class^="icon-"], [class*=" icon-"] {
//     font-size: 22px;
//     line-height: 0;
//     position: relative;
//     top: 2px;
//     margin-right: $bsu-xs;
// }

.color-blue {
    color: $color-brand;
}
.color-blue-20 {
    color: $color-blue-50;
}

.hr {
    height: 0;
    border: 0;
    border-top: 1px solid #c7d4df;
}
