
.page-container {
    @extend .container;
    position: relative;

    // width: 100%;
    background: #fff;

    min-height: 75vh;

    @include media-query(lap-and-up) {
        min-height: 600px;
    }
    // min-height: 100%;

    &:before {
        content: "";

        @include position-absolute(0,0);
        opacity: 0;

        background: rgba(#333, .3);
        transition: opacity .2s ease-in;
    }

    &--inactive {

        &:before {
            width: 100%;
            height: 100%;
            // content: "";
            z-index: 1;
            opacity: 1;
        }
    }

}
