
figure {
    @extend .figure;
}

figcaption, .wp-block-image figcaption {
    @extend .figure__caption;
}


.figure,
%figure {
    // max-width: 250px;
    display: inline-block;

    > img {
        width: auto;
        max-width: 100%;
    }

    &__caption {
        margin-top: 0;
        padding: $bsu-xs;

        color: $color-brand;
        background: $color-blue-20;

        @include inuit-font-size(13px, 1.4);
    }
}


