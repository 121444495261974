
$inuit-heading-size-1:  32px;
$inuit-heading-size-2:  26px;
$inuit-heading-size-3:  18px;
$inuit-heading-size-4:  17px;
$inuit-heading-size-5:  16px;
$inuit-heading-size-6:  14px;


h1,
.#{$inuit-namespace}alpha,
%#{$inuit-namespace}alpha {
    @include inuit-font-size($inuit-heading-size-1, $heading-line-height-factor);

    color: $color-brand;
}

h2,
.#{$inuit-namespace}beta,
%#{$inuit-namespace}beta {
    @include inuit-font-size($inuit-heading-size-2, $heading-line-height-factor);
}

h3,
.#{$inuit-namespace}gamma,
%#{$inuit-namespace}gamma {
    @include inuit-font-size($inuit-heading-size-3);
    // font-weight: 600;
}

h4,
.#{$inuit-namespace}delta,
%#{$inuit-namespace}delta {
    @include inuit-font-size($inuit-heading-size-4);
}

h5,
.#{$inuit-namespace}epsilon,
%#{$inuit-namespace}epsilon {
    @include inuit-font-size($inuit-heading-size-5);
}

h6,
.#{$inuit-namespace}zeta,
%#{$inuit-namespace}zeta {
    @include inuit-font-size($inuit-heading-size-6);
}
