

.data-table {
    @include inuit-font-size(14px, false);

    th {
        font-weight: 700;
        white-space: nowrap;
    }
    td {
        vertical-align: top;
    }

    &--bordered {
        // border: 1px solid #000;

        th {
            color: white;
            background: $color-blue-50;
        }
        tbody tr:nth-of-type(odd), tr.odd {

        }
        tbody tr:nth-of-type(even), tr.even {
            background-color: rgba($color-blue-positive, 0.1);
            // background-color: $color-gray-mid;
            a:not(.btn) {
                @include underline-shadow(alphaize(rgba($color-blue-positive, 0.1)));

            }
        }

        td {

            border-left:1px solid alphaize(rgba($color-blue-positive, 0.3));
            border-right:1px solid alphaize(rgba($color-blue-positive, 0.3));

            // &:empty {
            //     border:none;
            // }
        }

        th {
            border-left:1px solid alphaize(rgba($color-blue-positive, 0.3));
            border-right:1px solid alphaize(rgba($color-blue-positive, 0.3));
            &:first-child {
                border-left-color: $color-blue-50;
            }
            &:last-child {
                border-right-color: $color-blue-50;
            }
            // &:empty {
            //     border:none;
            // }
        }

        thead tr:last-child th{
            border-bottom-width:2px;
        }

        tbody tr th:last-of-type{

            border-right-width:2px;
        }

        tbody tr:last-child td {
            border-bottom: 1px solid alphaize(rgba($color-blue-positive, 0.3));
        }
    }

    &--listing {

        th {
            color: $color-brand;
            background: $color-blue-20;

            border-left:1px solid alphaize(rgba($color-blue-positive, 0.3));
            border-right:1px solid alphaize(rgba($color-blue-positive, 0.3));
        }

    }

    &--listing-rows {
        td, th {
            border-left-width: 0;
            border-right-width: 0;
        }

        th:first-child,
        td:first-child {
            border-left-width: 1px;
            // border-right-width: 0;
        }
        th:last-child,
        td:last-child {
            border-right-width: 1px;
            // border-left-width: 0;
        }
    }

    &--fixed {
        table-layout: fixed;
        width: 100%;

    }

    &--loading {
        transition: opacity .3s ease-in;
        opacity: 0.5;
    }

}
