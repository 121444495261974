
.def-list {
    @extend .clearfix;
    @extend .list-bare;

    > dt, dd {
        @extend %def-list__item;
    }

    > dt {
        @extend %def-list__title;
    }

    > dd {
        @extend %def-list__def;
    }



}
    %def-list__title {
        float: left;

        width: 30%;

        @include media-query(palm-and-up) {
            float: none;
            width: auto;
        }

        font-weight: 700;

        &:after {
            content: ":";
            font-weight: 700;
        }

    }


    %def-list__item {
        display: inline-block;

    }

    %def-list__def {
        margin-left: 0;
        width: 70%;

        @include media-query(palm-and-up) {
            margin-left: $bsu-xs;
            margin-right: $bsu;

            width: auto;
        }

    }


.def-list-flex {
    display: flex;
    flex-flow: row wrap;

    > dt {
        width: 40%;
        margin-right: 2%;
        margin-bottom: $bsu-s;
    }

    > dd {
        margin-left: 0;
        width: 58%;
        align-self: flex-end;
        margin-bottom: $bsu-s;

        &::after {
            // content: "";
            page-break-after: always;
            break-after: always;
        }

        > dl {
            margin-bottom: 0;

            dd {
                margin-left: 0;
                &::before {
                    content: "\2022";
                    display: inline-block;
                    margin-right: $bsu-xs;
                }
            }
        }
    }
}
