
.slider {
    display: block;

    margin-bottom: $bsu-s;
    position: relative;

    opacity: 1;

    &__categories {
        @extend .clearfix;
        @extend .list-bare;
        @extend .list-inline;
        margin-bottom: 0;

        @include position-absolute(auto, auto);

        z-index: 2;

        @include inuit-font-size(13px, false);

        > li {
            display: inline-block;
            width: auto;
            float: left;

            > a {
                display: block;
                margin-right: 2px;
                padding: 0.5*$bsu-xs $bsu-xs;

                background: rgba(white, 0.8);

                transform: background .1s ease-in-out;

                font-weight: 600;


                &:hover {
                    background: $color-blue-positive;
                    color: white;
                }
            }
            &.current, &.tns-nav-active {
                > a {
                    font-weight: 700;
                }
            }

            &:last-child {
                > a {
                    border-right: 0;
                }
            }

        }

    }

    &__item {
        .tile--large {
            margin-bottom: 0;
        }

        > .img-ratio {
            padding-bottom: 60%;
        }

        .tile--positive {
            background: $color-brand;
        }
    }
}
