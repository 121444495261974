@charset "UTF-8";

@import "../../../dsv/src/styles/dsv/tools.functions";
@import "../../../dsv/src/styles/bower_components/inuit-defaults/settings.defaults";

@import "../../../dsv/src/styles/dsv/iconify/icons.svg";


@import "../../../dsv/src/styles/dsv/settings.defaults";
@import "../../../dsv/src/styles/dsv/settings.colors";
@import "youth/settings.colors";

@import "../../../dsv/src/styles/dsv/settings.vars";
@import "../../../dsv/src/styles/dsv/settings.responsive";

@import "../../../dsv/src/styles/bower_components/inuit-functions/tools.functions";
@import "../../../dsv/src/styles/bower_components/inuit-mixins/tools.mixins";
@import "../../../dsv/src/styles/bower_components/inuit-responsive-tools/tools.responsive";
@import "../../../dsv/src/styles/bower_components/inuit-tools-widths/tools.widths";

@import "../../../dsv/src/styles/dsv/tools.mixins";


@import "../../../dsv/src/styles/bower_components/inuit-normalize/generic.normalize";
@import "../../../dsv/src/styles/bower_components/inuit-reset/generic.reset";
@import "../../../dsv/src/styles/bower_components/inuit-box-sizing/generic.box-sizing";
@import "../../../dsv/src/styles/bower_components/inuit-shared/generic.shared";

@import "../../../dsv/src/styles/bower_components/inuit-page/base.page";
// @import "../../../dsv/src/styles/bower_components/inuit-headings/base.headings";
@import "../../../dsv/src/styles/bower_components/inuit-paragraphs/base.paragraphs";
@import "../../../dsv/src/styles/bower_components/inuit-images/base.images";

@import "../../../dsv/src/styles/dsv/base.headings";
@import "../../../dsv/src/styles/dsv/base.tables";
@import "../../../dsv/src/styles/dsv/base.images";

@import "../../../dsv/src/styles/bower_components/inuit-layout/objects.layout";
@import "../../../dsv/src/styles/bower_components/inuit-box/objects.box";
@import "../../../dsv/src/styles/bower_components/inuit-list-inline/objects.list-inline";
@import "../../../dsv/src/styles/bower_components/inuit-list-ui/objects.list-ui";
@import "../../../dsv/src/styles/bower_components/inuit-list-block/objects.list-block";
@import "../../../dsv/src/styles/bower_components/inuit-list-bare/objects.list-bare";
@import "../../../dsv/src/styles/bower_components/inuit-buttons/objects.buttons";
@import "../../../dsv/src/styles/bower_components/inuit-media/objects.media";


@import "../../../dsv/src/styles/bower_components/inuit-clearfix/trumps.clearfix";
@import "../../../dsv/src/styles/bower_components/inuit-widths/trumps.widths";
@import "../../../dsv/src/styles/bower_components/inuit-spacing/trumps.spacing";
@import "../../../dsv/src/styles/bower_components/inuit-spacing-responsive/trumps.spacing-responsive";
@import "../../../dsv/src/styles/bower_components/inuit-widths-responsive/trumps.widths-responsive";


@import "../../../dsv/src/styles/dsv/objects.container";
@import "../../../dsv/src/styles/dsv/objects.layout";
@import "../../../dsv/src/styles/dsv/objects.accordion";
@import "../../../dsv/src/styles/dsv/objects.tile";
@import "../../../dsv/src/styles/dsv/objects.headline";
@import "../../../dsv/src/styles/dsv/objects.flyout";
@import "../../../dsv/src/styles/dsv/objects.link-list";
@import "../../../dsv/src/styles/dsv/objects.filter-list";
@import "../../../dsv/src/styles/dsv/objects.media-list";
@import "../../../dsv/src/styles/dsv/objects.def-list";
@import "../../../dsv/src/styles/dsv/objects.hamburger";
@import "../../../dsv/src/styles/dsv/objects.mp-menu";
@import "../../../dsv/src/styles/dsv/objects.figure";
@import "../../../dsv/src/styles/dsv/objects.buttons";
@import "../../../dsv/src/styles/dsv/objects.breadcrumb";
@import "../../../dsv/src/styles/dsv/objects.data-table";
@import "../../../dsv/src/styles/dsv/objects.slider";
@import "../../../dsv/src/styles/dsv/objects.box";
@import "../../../dsv/src/styles/dsv/objects.tabs";
@import "../../../dsv/src/styles/dsv/objects.alert";


@import "../../../dsv/src/styles/dsv/trumps.helper";
@import "../../../dsv/src/styles/dsv/trumps.icons";
@import "../../../dsv/src/styles/dsv/trumps.featherlight";

@import "../../../dsv/src/styles/dsv/components.base";

@import "../../../dsv/src/styles/dsv/components.article";
@import "../../../dsv/src/styles/dsv/components.form";
@import "../../../dsv/src/styles/dsv/components.page-header";
@import "../../../dsv/src/styles/dsv/components.page-subnavigation";
@import "../../../dsv/src/styles/dsv/components.page-container";

.page-wrapper {
    margin: 0 auto;
    max-width: $container-max;
}

@import "../../../dsv/src/styles/dsv/components.page-footer";
@import "../../../dsv/src/styles/dsv/components.wordpress";


@import "../../../dsv/src/styles/dsv/base.headings";

// @import "../../../dsv/src/styles/dsv/components.wordpress";
@import "../../../dsv/src/styles/dsv/objects.alert";

@import "youth/components.base";
@import "youth/components.page-header";
@import "youth/components.page-navigation";
@import "youth/components.footer";

@import "youth/base.headings";
@import "youth/trumps.overwrites";

// @import "common/variables";

// Import npm dependencies
// @import "~bootstrap/scss/bootstrap";
// @import "~font-awesome/scss/font-awesome";

// @import "common/global";
// @import "components/buttons";
// @import "components/comments";
// @import "components/forms";
// @import "components/grid";
// @import "components/wp-classes";
// @import "layouts/header";
// @import "layouts/sidebar";
// @import "layouts/footer";
// @import "layouts/pages";
// @import "layouts/posts";
// @import "layouts/tinymce";
