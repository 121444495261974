

$inuit-btn-padding:         $bsu-s;
$inuit-btn-padding--small:  $bsu-xs;
$inuit-btn-padding--large:  $bsu;
$inuit-btn-background:      $color-blue-negative;

$inuit-btn-color:           #fff !default;
$inuit-btn-color:    $color-brand;
$inuit-btn-active-color:    $color-red;
$inuit-btn-border-width:    1px !default;
$inuit-btn-border-style:    solid !default;
$inuit-btn-border-color:    $inuit-btn-background;
$inuit-btn-radius:          0 !default;


@mixin btn {
    margin-bottom: $bsu-s;
    padding: $inuit-btn-padding--small - $inuit-btn-border-width $inuit-btn-padding - $inuit-btn-border-width;
    background-color: $inuit-btn-background;
    border-color: $inuit-btn-border-color;
    color: $inuit-btn-color;
    // font-weight: 600;

    text-shadow: none !important;
    background-image: none;

    &:hover,
    &:focus {
        background-color: darken($inuit-btn-background, 5);
        color: $inuit-btn-active-color;
        // font-weight: 600;
        // box-shadow: 1px 0 2px $color-blue-gray;
    }

    &:focus-visible {
        outline: 2px dashed $color-brand;
        outline-offset: 3px;
    }

    &--small {
        padding: .5*$inuit-btn-padding--small - $inuit-btn-border-width .5*$inuit-btn-padding - $inuit-btn-border-width ;
    }

    &[target="_blank"] {
        &:after { content: none; }
    }

    &--brand {
        background: $color-brand;
        &, &:hover {
            color: white;
        }
        &:hover {
            background: lighten($color-brand, 5);

        }
    }
}

.btn {
    @include btn;
}

button {
    @extend .btn;
}

.btn--facebook {
    background: $brand-facebook;
    color: white;
    border-color: $brand-facebook;

    &:focus,
    &:hover, .no-touchevents &:hover {
        color: white;
        background: lighten($brand-facebook, 5);
    }
}
.btn--instagram {
    background: $brand-instagram;
    color: white;
    border-color: $brand-instagram;
    &:focus,
    &:hover, .no-touchevents &:hover {
        color: white;
        background: lighten($brand-instagram, 5);
    }
}

.btn--youtube {
    background: $brand-youtube;
    color: white;
    border-color: $brand-youtube;

    &:focus,
    &:hover, .no-touchevents &:hover {
        color: white;
        background: darken($brand-youtube, 5);
    }
}

.btn--table {
    background-color: #ccdce7;
    border-color: #ccdce7;

    &:hover {
       background-color: rgba(#ccdce7, .7);
    }
}

// .btn--download {
//     @extend .btn;
// //  @extend .btn-small;
// //            padding: $inuit-btn-padding--small - $inuit-btn-border-width  double($inuit-btn-padding--small) - $inuit-btn-border-width; /* [7] */

//     margin-bottom: 0;

// }
