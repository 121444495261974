@import "../bower_components/inuit-list-bare/objects.list-bare";
@import "../bower_components/inuit-clearfix/trumps.clearfix";
@import "../bower_components/inuit-list-inline/objects.list-inline";
@import "trumps.icons";
@import "iconify/icons_placeholder.svg";


.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0 0, 0 0,0 0, 0 0);
    position: absolute !important;
    white-space: nowrap;
    height: 1px;
    width: 1px;
    overflow: hidden;

    &:focus {
        clip: auto !important;
        display: block;
        height: auto;
        left: 5px;
        top: 5px;
        width: auto;
        z-index: 100000; /* Above WP toolbar. */
    }
}


.nav-links {
    @extend .clearfix;

    &__previous, &__next {
        display: inline-block;
        width: auto;

        font-weight: 600;
    }

    &__previous {
        float: left;
    }
    &__next {
        float: right;
    }

    .icon {
        top: 5px;
        background-size: cover;
    }
}

.pagination-pages {
    @extend .clearfix;

    text-align: right;
}

.pagination {
    // @extend .zeta;
    @include inuit-font-size(13px, false);
    text-align: right;

    &__pages {
        display: inline-block;
        margin-right: $bsu;
        @include inuit-font-size(13px, false);
        font-style: italic;
        color: $color-blue-50;
    }

    &__list {
        @extend %list-bare;
        @extend %list-inline;

        > li {
            @include reset-pseudo();

            display: inline-block;
            border: 1px solid $color-blue-20;
            border-left-width: 0;

            padding-left: 0;
            text-indent: 0;

            .current, &:hover {
                background-color: $color-blue-20;
                color: $color-brand;
            }

            &:first-child {
                border-left-width: 1px;
            }

            .icon {
                @extend .icon--small;
                background-size: cover;
            }

        }

        .page-numbers {
            display: block;
            padding: 0.5*$bsu-xs $bsu-xs;
        }
    }

    &--inline {
        display: inline-block;

        @include inuit-font-size(13px, false);
        text-align: right;

        .pagination__item {
            display: inline-block;
            border: 1px solid $color-blue-20;
            border-left-width: 0;

            padding: 0.5*$bsu-xs $bsu-xs;
            text-indent: 0;
            background-color: $color-blue-20;
        }

        a {
            &:first-child {
                .pagination__item {
                    border-left-width: 1px;
                }
            }

            .pagination__item {
                background: none;
            }

            &:hover {
                .pagination__item {
                    background-color: $color-blue-20;
                }
            }

        }
    }
}

.widget {
    &.transparent-tile  {
        h4, .widgettitle {
            // @extend .epsilon;
            //@extend .transparent-tile__title;
            @extend .headline--soft !optional;
            margin-bottom: $bsu-xs;

            .icon {
                height: 18px;
            }
        }
    }

    &.widget-sponsors {

    }
}


.fluid-width-video-wrapper {
    // margin-bottom: $bsu;
}

.aligncenter {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.acf-field {

    .acf-label label {
        @include inuit-font-size(14px, false);
    }

    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    .acf-input,
    .acf-input-wrap {
        @include inuit-font-size(14px, 2);
        height: auto;
    }
}

.acf-fields {
    .acf-field {
        textarea,
        input[type="text"],
        input[type="number"],
        input[type="email"] {
            // @include inuit-font-size($inuit-base-font-size);
            // @extend %input;
              @include defaultinput();


        }
    }
}

.acf-field-label {

    padding-bottom: 0 !important;

    > .acf-label {
        margin-bottom: 0;
        @extend .beta;
        color: $color-brand;

        > label {
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            margin: 0;
        }

        > p {
            @include inuit-font-size($inuit-base-font-size);
        }
    }

}

.acf-field-label {
    + .acf-field {
        border-top-width: 0;
    }
}

.acf-clubreport {

    button[type="submit"],
    input[type="submit"] {
        width: 100%;
    }
    .acf-form-fields {
        @extend .clearfix;
    }

    .acf-fields > .acf-field { clear: none; }

    .acf-field {
        float: left;
        width: 100%;

        &.u-1\/4-desk,
        &.u-1\/3-desk,
        &.u-1\/2-palm-wide-and-up {
            border-left: 1px solid #eee;
        }

        + .acf-field-label {
            margin-top: $bsu;
            border-top-width: 0;
            border-bottom: 1px solid $color-blue-gray;
            padding-top: 0;
        }
    }
}


.widget_link_list {
    .widgettitle {
        &:before {
            content: " ";
            margin-right: 3px;

            @extend .icon;
            @extend %icon-world-outline;

            height: 18px;
        }
    }
}

.widget_media_image_bordered {
    border: 1px solid $color-blue-negative;
    border-bottom-width: 0;
}

.widget_search {
    margin-bottom: $bsu;

    border: 1px solid $color-blue-negative;
    padding: ($bsu-xs - 2px) 0;

    input[type="search"] {
        box-sizing: border-box;
        @include searchinput;
    }
}


.post-password-form {
    label {
        float: left;
    }
}
