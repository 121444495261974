/**


**/


.layout,
%layout {
    @extend %clearfix;
}

    .layout__item, %layout__item {
        float: left;
    }

.layout--rev,
%layout--rev {

    > .layout__item, %layout__item {
        float: right;
    }
}

.layout--flex,
%layout--flex {
    display: flex;

    flex-wrap: wrap;
}

.layout--center,
%layout--center {
    > .layout__item, %layout__item {
        float: none;
    }
}


@mixin layout($layout__item: '.layout__item') {
    @include clearfix();

    list-style: none;
    margin:  0;
    padding: 0;
    margin-left: -$bsu;

    > #{$layout__item} {
        float: left;

        margin: 0;
        padding-left: $bsu;

        list-style: none;
    }
}

@mixin layout--large($layout__item: '.layout__item') {
    margin-left: -$bsu-l;

    > #{$layout__item} {
        padding-left: $bsu-l;
    }
}


// @include media-query(lap-and-up) {

//     .lap-and-up--layout {
//         list-style: none;
//         margin:  0;
//         padding: 0;
//         margin-left: -$bsu;

//         > .layout__item {
//             padding-left: $bsu;
//         }

//     }


// }
