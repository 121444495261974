
input, textarea, %input {
    @include defaultinput();
}

textarea {
    padding: $bsu-xs;
}


#wpmem_login {
    input[type="checkbox"] {
        position: absolute !important;
        left: -9999px !important;
    }
}

input[type="checkbox"],
input[type="radio"] {
    // position: absolute !important;
    // left: -9999px !important;

    box-shadow: none;
    border: 0;

    &:focus {
        box-shadow: none;
    }
}

input[type="radio"] {
    position: absolute !important;
    left: -9999px !important;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
    position: relative;

    padding-left: $bsu;
    margin-bottom: $bsu-xs;

    line-height: $base-line-height;

    cursor: pointer;

    &:before {
        // @include position-absolute($bsu - 10px, $bsu - 10px, 5px, 0);
        content: "";

        display: inline-block;
        vertical-align: middle;

        margin-left: -$bsu;
        margin-right: 10px;

        border: 1px solid $color-blue-50;
        background: #fff;

        width: 18px;
        height: 18px;
    }

    &:after {
        @include position-absolute($bsu-s + 4px, $bsu-s + 4px, 5px, 5px);

        content: "\2715";

        display: block;

        opacity: 0;

        transition: opacity .1s ease-in;

        @include inuit-font-size(16px);
        line-height: $bsu;

        color: $color-blue-positive;
        top: 0px;
        left: 3px;

        width: $bsu-s - 2px;
        height: $bsu-s - 2px;
    }
}

input[type="radio"] + label {

    &:before, &:after {
        border-radius: 50%;
    }
    &:after {
        content: "";

        background: $color-blue-positive;

        top: 9px;
        left: 3px;

        width: $bsu-s - 2px;
        height: $bsu-s - 2px;
    }
}


input[type="checkbox"]:checked + label:after,
input[type="radio"]:checked + label:after {
    opacity: 1;
}

label > input[type="checkbox"],
label > input[type="radio"] {
    position: static !important;

}

input[type="search"],
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin: 0;
}


::placeholder {
    color: $color-blue-50;
}

%input--positive {
    // color: $color-blue-50;
    color: #fff;
    background: $color-blue-50;
}

button[type="submit"], input[type="submit"] {
    @extend %input;
    @extend %input--positive;
}

form {
    label {
        display: block;
    }
}


.form {
    &--full {
        input,
        textarea, button {
            width: 100%;
            margin-bottom: 2px;
        }
    }

    &--container {
        padding: $bsu-s;
        background: rgba($color-blue-positive, 0.1);

        margin-bottom: $bsu;
    }
}
.form-field-error {
    color: $color-red;
}

.ninja-forms-cont {

    @include media-query(desk) {
        width: 70%;
    }

    .ninja-forms-req-symbol, .ninja-forms-field-error {
        color: $color-red;
    }

}

.ninja-forms-field-error {
    @include inuit-font-size(13px, false);

    p {
        margin-bottom: 0;
    }
}

.ninja-forms-response-msg {
    margin-bottom: $bsu;
    background: $color-blue-negative;

    > p { margin-bottom: 0;}
}
.ninja-forms-error-msg {
    padding: $bsu-xs $bsu-s;
}
.ninja-forms-error-msg {
    background: rgba($color-red, 1);
    color: #fff;
}

.ninja-forms-error {
    .ninja-forms-field.ninja-forms-req {
        border: 1px solid $color-red;
    }
}

input[type="submit"].ninja-forms-field {
    padding-left: $bsu;
    padding-right: $bsu;
}

.list-select-wrap .selectize-control  {
    &, div {
        display: block;

        .selectize-input > .item {
            display: inline-block;
        }

        .selectize-dropdown-content > div {
            padding: $bsu-xs;
        }
    }

}

.list-radio-wrap {
    ul {
        margin-left: $bsu-s;
        > li {
            @include reset-pseudo();
            text-indent: 0;
            padding-left: $bsu;

            label {
                margin-left: $bsu;
                > input {
                    margin-left: -$bsu;
                    margin-right: $bsu-s !important;
                }
            }

            input:checked +label:after {
                top: 9px;
            }
        }
    }

}

@include media-query(lap-and-up) {

    .u-1\/4-offset-lap-and-up {
        margin-left: 25%;
    }
}

.pretty input {
    left: 0 !important;
}

/* Overwriting some Pretty-Checkbox styles to allow for word wrapping */
.pretty {
    white-space: normal;
    width: 100%;
}

.pretty .state label
{
    text-indent: 0;
    padding-left: 2rem;
}

.pretty .state label:after,
.pretty .state label:before,
.pretty.p-icon .state .icon
{
    top: 0 !important;
}

.pretty .state label:after, .pretty .state label:before, .pretty.p-icon .state .icon, .pretty.p-svg .state .svg { top: 0!important; }


.pretty a {
    position: relative;
    z-index: 10;
}

.nl2go-widget {
    input[type="checkbox"] {
        position: absolute;
        left: -9999px !important;
    }
}

