/**

**/
@import "../bower_components/inuit-list-bare/objects.list-bare";

.page-footer {
    // background: $color-blue-negative;
    background: $color-blue-gray;
    padding: $bsu-s;

    &__inner {
        @extend .page-wrapper !optional;
    }

    @include inuit-font-size(13px, 1.75);

    .accordion {
        @include media-query(portable) {
            @include accordion(".accordion__action", ".link-list", ".accordion--active", true);

            &__action > a {
                pointer-events: none;
            }
        }
    }

    .headline {
        padding-left: quarter($bsu);
        margin-bottom: halve($bsu-s);
    }
    .link-list {
        @extend .list-bare;
        //@extend .link-list--dot;

        margin-bottom: $bsu-s;

        .current_page_item {
            > a {
                color: $color-brand-alt;
            }
        }
    }

    &__search{
        margin: $bsu-s 0;
        background: #fff;
        border: 1px solid $color-blue-negative;
        padding: ($bsu-xs - 2px) 0;

        input[type="search"] {
            box-sizing: border-box;
            @include searchinput;
        }

        @include media-query(lap-and-up) {
            // margin-top: $bsu;

            text-align: center;
        }
    }

    &__legal {
        text-align: center;
        @include media-query(desk) {
            text-align: left;
        }
    }
}



#sidebar-social-media {
    position: fixed;
    left: 0;
    top: 40vh;
    a {
        &.btn {
            padding-top: 9px;
            padding-bottom: 10px;
            min-width: 20px;
        }
    }
    ul {
        margin-bottom: 0;
    }
}
