/**

**/
.tile-list {
    @extend .layout;

    list-style: none;
    // display: table;
    // margin-left: 0;
}

.tile-list--small {
    @extend .layout--small;

    .tile--medium {
        margin-bottom: $bsu-s;
    }
}

.tile-list__item {
    @extend .layout__item;
    // display: table-cell;

    @include reset-pseudo();

    // padding-left: 0;
    text-indent: 0;

    > .tile--medium {

    }
}


.tile {
    position: relative;

    display: inline-block;
    margin-bottom: $bsu;
    width: 100%;

    vertical-align: top;
    overflow: hidden;

    &__container {
        position: relative;
        width: 100%;

        &:after {
            content: "";
            display: block;
            padding-bottom: aspect-ratio-percentage(16, 9);
        }

        &__img {
            @include position-absolute();
            overflow: hidden;

            > img {
                width: 100%;
            }
        }
    }

    &:hover {
        .tile__container {
            &:before {
                content: "";
                display: block;
                background: rgba($color-blue-positive, 0.3);
            }
        }
    }

        & a.tile {
        .tile__container {
            &:before {
                @include position-absolute();
                content: "";
                opacity: 0;
                transition: all .2s ease-in;
            }

            .tile__container__img:after {
                // @extend .icon;
                // @extend .icon.icon-tabs-outline;
                background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M18%204H8c-1.104%200-2%20.896-2%202v2H5c-1.104%200-2%20.896-2%202v9c0%201.104.896%202%202%202h9c1.104%200%202-.896%202-2v-1h2c1.104%200%202-.896%202-2V6c0-1.104-.896-2-2-2zM5%2019v-9h8.5c.275%200%20.5.225.5.5V19H5zm13-3h-3v-5.5c0-.827-.673-1.5-1.5-1.5H8V6h10v10z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');

                @include position-absolute(auto, auto, 50%, 50%);
                transform: translateX(-50%) translateY(-50%);

                display: inline-block;
                width: $bsu-l;
                height: $bsu-l;

                content: "";


                // background: url("../img/typicons/SVG/tabs-outline.svg") center center no-repeat;
                background-position: center center;

                background-size: cover;

                @include inuit-font-size($inuit-heading-size-4);
                font-weight: 900;

                color: white;
                text-shadow: 1px 1px #000;

                transition: all .1s ease-in .1s;

                opacity: 0;
            }
        }

        &:hover {
            .tile__container {
                &:before {
                    opacity: 1;
                    background: rgba(#000, 0.3);
                    z-index: 1;
                }
                .tile__container__img:after {
                    opacity: 1;
                    // background: rgba($color-brand, 0.3);
                    z-index: 2;
                }
            }
        }}


    &__img {
        @include position-absolute(100%, auto, 0, 0);
        max-width: none; /** */
    }

    &__caption {
        padding: halve($bsu-s) $bsu-s;
        margin-bottom: 0;

        color: $color-blue-positive;
        background: $color-blue-negative;

        text-decoration: none;
    }

    &--positive {

        .tile__caption {
            color: #fff;
            background: $color-blue-positive;
        }
    }


    &--small {
        .tile__container {
            &:after {
                // padding-bottom: aspect-ratio-percentage(4, 3);
                padding-bottom: aspect-ratio-percentage(2, 1);
            }
        }

        .tile__caption {
            height: auto;

            @include media-query(palm-wide-and-up) {
                height: $bsu-l;
            }

            font-weight: 900;
            @include inuit-font-size($base-font-size, 1.4);
        }

        &:hover  {
            .tile__caption {
                color: $color-brand-alt;
            }
        }
    }

    &--medium {
        @extend .tile--cta;

        .tile__caption {

            height: auto;

            @include media-query(palm-wide) {
                height: 90px;
            }
            @include media-query(lap) {
                height: 100px;
            }
            @include media-query(desk) {
                height: 90px;
            }

            @include inuit-font-size(13px, 1.4);

            > strong {
                @include inuit-font-size(15px, 1.4);
                display: block;
            }

            > p {
                margin-bottom: 0;
            }
        }
    }

    &--large {
        .tile__container {
            &:after {
                padding-bottom: 45%;
            }
        }

        .tile__caption {
            min-height: $inuit-heading-size-4*1.25 + 2*$inuit-heading-size-2*1.25 + $bsu-s;
        }

        .tile__caption__small {
            display: block;

            @include inuit-font-size($inuit-heading-size-4, 1.25);
            font-weight: 700;
        }

        .tile__caption__large {
            display: block;

            @include inuit-font-size($inuit-heading-size-2, 1.25);
            font-weight: 900;
        }

        // &:hover {
        //     .tile__caption {
        //         background: lighten($color-blue-positive, 10);
        //     }
        // }
    }

    &--cta {
        .tile__caption {
            &:after {
                position: absolute;

                content: "+";
                right: $bsu-s;
                bottom: halve($bsu-s);
            }
        }
        &:hover {
            .tile__caption {
                > strong {
                    color: $color-brand-alt;
                }
            }
        }
    }
}

    .content-tile {
        position: relative;

        display: inline-block;
        width: 100%;
        margin-bottom: $bsu-s;

        color: $color-blue-positive;

        &__header {
            padding: quarter($bsu-s) halve($bsu-s);
            margin-bottom: 0;

            background: $color-blue-gray;

            @include inuit-font-size(13px);
            font-weight: 600;
        }

        &__container {
            position: relative;
        }

        &__body, &__img {
            display: table-cell;
            margin-bottom: 0;
        }

        &__container {
            display: block;

            padding: halve($bsu-s);
            // padding-right: 26%;

            background: $color-blue-negative;

            transition: background .1s ease-in-out;

            @include inuit-font-size(13px, 1.4);

            @include media-query(lap) {
                padding-right: halve($bsu-s);
            }

        }

        &__title {
            display: block;

            @include inuit-font-size(13px, 1.4);
            font-weight: 700;

            margin-bottom: halve($bsu-s);
        }

        &__img {
            @include media-query(lap) {
                display: none;
            }

            @include position-absolute(25%, 100%, 0, auto);
            right: 0;

            // position: absolute;
            // right: 0;
            // top: $bsu + quarter($bsu-s);
            // bottom: 0;

            overflow: hidden;

            // width: 25%;
            // min-width: 80px;

            > img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                max-width: none;
            }
        }

        .no-touchevents &:hover {
            color: $color-blue-positive;

            .content-tile__title {
                color: $color-brand-alt;
            }
            .content-tile__container {
                background: $color-blue-20;
            }
        }
    }


    .transparent-tile {
        margin-bottom: $bsu;

        &__title {
            @extend .headline--soft !optional;
            margin-bottom: $bsu-xs;

            .icon {
                height: 18px;
            }
        }

        > p, > ul {
            @include inuit-font-size(15px, 1.4);
            margin-bottom: 0;
        }
    }

    .tile--tokyo {

        &__img {
            overflow: hidden;
            img {
                @include position-absolute(auto, 100%, 50%, 50%);
                transform: translate(-50%, -50%);
                max-width: none;
            }
            &:before {
                display: block;
                content: "";
                width: 100%;

                padding-top: 78.405344%;

                @include media-query(palm-and-up) {
                    padding-top: 60.30534351145038%;
                }
            }
        }

        &__banner {
            &:before, &:after {
                content:"";
                display: block;
                height: 2px;
                background-image: linear-gradient(to right,
                    $olympia-blue,
                    $olympia-yellow,
                    $olympia-black,
                    $olympia-green,
                    $olympia-red
                );
            }

            text-align: center;

            padding: $bsu $bsu;
            min-width: 340px;

            top: 15%;
            @include inuit-font-size(32px);

            @include media-query(palm-and-up) {
                min-width: 400px;

                @include inuit-font-size(38px);

                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            @include media-query(desk) {
                padding-left: 1.5*$bsu;
                padding-right: 1.5*$bsu;
                min-width: 440px;
            }

            background: rgba(#fff, 1);

            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            display: inline-block;
            width: auto;

            z-index: 5;

            .tile--tokyo__countdown {
                font-family: 'D-DIN', sans-serif;
            }
        }

        &__title {
            font-family: 'D-DIN', sans-serif;
            font-weight: 700;
            text-transform: uppercase;

            color: $color-tokyo;
            position: relative;

            @include inuit-font-size(42px);

        }

        .countdown {

            &__item {
                display: inline-block;
                padding: $bsu-s;

                &__time {
                    display: block;


                    @include inuit-font-size(32px, 1);

                    &[data-timer="days"] {
                        color: $olympia-blue;
                    }

                    &[data-timer="hours"] {
                        color: $olympia-yellow;
                    }

                    &[data-timer="minutes"] {
                        color: $olympia-green;
                    }
                }

                &__text {
                    display: block;
                    color: $color-tokyo;

                    @include inuit-font-size(14px, 1);

                    @include media-query(lap-and-up) {
                        @include inuit-font-size(16px, 1);
                    }
                }


            }
        }

    }
