

.box {

    &--meta {
        @extend .box--small;

        margin-left: $bsu;
        margin-bottom: $bsu;

        background: $color-blue-negative;
        color: $color-brand;

    }

    &--bordered {
        // padding: $bsu-s;
        border: 1px solid $color-blue-negative;
    }
}
