
.icon {
    position: relative;
    top: 2px;

    $size: 20px;

    display: inline-block;
    width: $size;
    height: $size;

    fill: currentColor;


    &--tiny {
        width: halve($size);
        height: halve($size);
    }

    &--small {
        width: 0.75*$size;
        height: 0.75*$size;
    }

    &--large {
        width: 1.5*$size;
        height: 1.5*$size;
    }

    &--huge {
        width: double($size);
        height: double($size);
    }

    &--fill {
        background-size: cover;
    }
}


