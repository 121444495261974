
.page-header {
    position: relative;
    background: #fff;

    padding: $bsu-s 0 $bsu;

    &:after {
        content: "";

        position: absolute;
        top: 100%;
        left: 0;
        right: 0;

        height: $bsu-l*1.5;

        background: #fff;
        // background: linear-gradient(180deg, #fff 20%, darken($color-blue-bright, 30));
    }

    &__inner {
        @include clearfix;
        margin: 0 $bsu-s;
        max-width: $container-max;

        // text-align: center;

        > .brand {
            float: left;
            // display: inline-block;
            width: 165px;
        }

        > .brand-alt {
            display: block;
            width: 100px;
            float: right;
            margin-top: $bsu-s;
        }

        @include media-query(lap-and-up) {
            margin-left: auto;
            margin-right: auto;
        }

        @include media-query(lap) {
            > .brand {
                width: 200px;
            }
            > .brand-alt {
                width: 140px;
            }
        }

        @include media-query(desk) {
            text-align: left;

            > .brand {
                width: 280px;
            }

            > .brand-alt {
                width: 180px;
                margin-top: $bsu;
            }
        }
    }
}
