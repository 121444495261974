
html, body {
    min-height: 100%;
    font-family: "Open Sans", sans-serif;
    @include inuit-font-size($base-font-size);

    color: $color-text;
}

body {
    // -ms-word-break: break-all;

    /* Non standard for webkit */
    // word-break: break-word;

    @include media-query(small) {
        // word-break: break-word;
        hyphens: auto;
    }
}

html {
    // background-image: url('../images/dsv-youth-bg-pattern.png'), linear-gradient(180deg, #fff, #4b96c9 340px, #fff 100%);
    background-image: none;
    background: $color-blue-positive;
    // filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#fff', endColorstr='#9ae0f4');/*For IE7-8-9*/
}

a { //, a:link
    text-decoration: none;
    color: $color-brand-alt;
    transition: .1s color;
    cursor: pointer;

    .no-touchevents &:hover {
        color: $color-brand;
    }

    // &[target="_blank"] {
    //     &:after {
    //         content: "";
    //         color: $color-blue-20;

    //         @extend .icon;
    //         @extend .icon--small;
    //         @extend .icon-link;

    //         background-size: cover;
    //         margin-left: 2px;
    //         // width: $bsu-s;
    //         // height: $bsu-s;

    //     }
    //     &.no-icon:after {
    //         content: none;
    //     }
    // }

    &:focus,
    &:active {
        outline: 0;
    }

}
hr {
  &, &.line {
      height: 0;
      border: 0;
      padding-bottom: $bsu-s;
      margin-bottom: $bsu-s;

      &:before {
        content: "";
        border-top: 1px solid #a1daf8;

        display: block;

      }
  }
}

  .article__meta {

      color: $color-text-light;

      dd {
        color: $color-text-dark;
      }
  }

