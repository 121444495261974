
$border-width: 1px;

table {
    width: 100%;
    // table-layout: fixed;

    // display: block;
    // overflow-x: auto;

    @include media-query(desk) {
        table-layout: auto;

        display: table;
        overflow-x: hidden;
    }

    height: auto!important;

    th {
       // overflow-wrap: normal;
        word-break: normal;
    }
    &.no--border {
        th, td {
            border-width: 0;
        }
    }

    &.no--padding {
        th, td {
            padding: 0;
        }
    }
}

.table-responsive {
    margin-bottom: $bsu;

    overflow-x: auto;
    background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0)), linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0)), linear-gradient(to right, #c3c3c5, rgba(195, 195, 197, 0)), linear-gradient(to left, #c3c3c5, rgba(195, 195, 197, 0));
    background-position: 0 0, 100% 0, 0 0, 100% 0;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 10em 100%, 6em 100%, 1em 100%, 1em 100%;
    background-attachment: local, local, scroll, scroll;

    > table {
        margin-bottom: 0;

        // &.dataTable tbody tr {
        //     background-color: transparent;
        // }
    }

    // @include media-query(desk) {
    //     background: none;
    //     overflow: visible;

    //     > table {
    //         margin-bottom: $bsu;
    //     }
    // }
}

td, th {
    padding: $bsu-xs $bsu-xs;
    border: $border-width solid $color-blue-gray;

    @include media-query(palm) {
        padding: $bsu-xs;
    }

    overflow-wrap: break-word;

    text-align: left;

    &[width] {
        width: auto;
    }
    &[height] {
        height: auto;
    }
}


// mixin for table widths

// .t50    { width:50%; }
// .t100    { width:100%; }

@mixin table-setup($namespace: "") {
    $i: 5;
    @while $i < 101 {
        .#{$namespace}t#{$i} { width: percentage($i / 100); }
        $i: $i + 5;
    }
}

@include table-setup();
