.text--small {
    @include inuit-font-size(14px);
}
.text--center {
    text-align: center !important;
}

.text--right {
    text-align: right !important;
}

.weight--extrabold {
    font-weight: 900;
}

.weight--normal {
    font-weight: 400!important;
}

.weight--bold {
    font-weight: 700;
}

.weight--semibold {
    font-weight: 600;
}

.float--left, .alignleft {
    float: left;
}

.float--right, .alignright {
    float: right;
}

.img--left {
    float: left;
    margin-top: $bsu-xs;
    margin-right: $bsu-s;
    margin-bottom: 0;
}

.va--top {
    vertical-align: top;
}

.va--middle {
    vertical-align: middle !important;
}

%break-line {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
}

@each $state, $query  in $breakpoints {
    @include media-query($state) {
        .visuallyhidden--#{$state} {
            @include visuallyhidden();
        }
    }
}

.hidden {
    display: none;
}


.search-result__item {
    margin-bottom: $bsu-s;
    padding-bottom: $bsu-s;

    border-bottom: 1px solid $color-blue-20;
}

.img-ratio {
    position: relative;
    height: 0;
    background: #fff;

    > * {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}

.milli {
    @include inuit-font-size($inuit-heading-size-6, false);
}


.color-blue-50 {
    color: $color-blue-50 !important;
}
.color-black {
    color: #333 !important;
}

.muted {
    color: $icon-fill;
}

.line {
    margin-bottom: $bsu;
    margin-top: $bsu;
    border: 0;
    border-bottom: 1px solid $color-blue-gray;
}


.is-clipped {
    // overflow: hidden !important;
    position: relative;
    // position:fixed;
    top:0px;
    left:0px;
    overflow:hidden;
    height:100%;
}
