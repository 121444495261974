
%article {
    @extend .article;

    h1 + p  {
        @extend .article__teaser;

        &.no--teaser {
            color: inherit;
            font-weight: 400;
        }
    }

    p > a:not(.tile):not(.content-tile),
    ul li > a {
        @include underline-shadow();
        @include underline();

        &:hover {
            @include underline($color-brand-alt);
        }
    }

    ul {
        margin-left: 0;
        list-style: none;

        > li {
            padding-left: 1em;
            text-indent: -.7em;

            &:before {
                content: "•";
                color: $color-brand;
                font-weight: 900;
                padding-right: .35em;
            }
        }
    }

    .alignleft, .alignright {
        float: none;
        margin-right: 0;

        @include media-query(lap) {
            max-width: 40%;
        }
        @include media-query(desk) {
            max-width: 30%;
        }

        figure & {
            margin-bottom: $bsu-s;
        }
    }

    .alignleft {
        // margin-right: $bsu;
        @include media-query(lap-and-up) {
            margin-right: $bsu;
            float: left;

        }
    }
    .alignright {
        // margin-left: 0;
        @include media-query(lap-and-up) {
            margin-left: $bsu;
            float: right;
        }

    }

    .post-thumbnail {
        max-width: 250px;
        // float: left;
    }
}

article {
    @extend %article;
}


    .article {
        margin-bottom: $bsu;

        &__teaser {
            color: $color-brand;
            font-weight: 600;

            a {
                // color: $color-brand-alt;
            }
        }
    }

    .news-list {
        @extend .list-bare;

        color: $color-brand;

        margin-bottom: $bsu-l;

        a {
            font-weight: 600;
            font-style: italic;
            @include underline();
        }
    }

    .article__meta {
        @extend .zeta;
        @extend .def-list;

        color: $color-blue-50;
    }


    .entry-content-asset {
        padding: $bsu-s;
        background: $color-blue-20;
        margin-bottom: $bsu;
    }

// .article-title--list {
//     @extend .delta;
//     margin-bottom: $bsu-s;
// }
