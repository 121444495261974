@include media-query(small) {

    .mp-menu {
        @include position-absolute(300px);
        z-index: 1;

        transform: translate3d(-100%, 0, 0);

        background: #fff;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

    }

        .mp-level {
            @include position-absolute();

            &:after {
                z-index: -1;
            }
        }


            .mp-level:before,
            .mp-level:after,
            .mp-pusher:after {
                @include position-absolute();
                right: 0;
                top: auto;
                content: "";
                opacity: 0;
            }


    .scroller {
        // overflow-y: scroll;
    }

    .scroller,
    .scroller-inner {
        position: relative;
    }

    .container-wrapper {
        position: relative;
        overflow: hidden;
        background: #34495e;
    }

    // .menu-trigger {
    //     position: relative;
    //     padding-left: 60px;
    //     font-size: 0.9em;
    // }

    // .menu-trigger:before {
    //     position: absolute;
    //     top: 2px;
    //     left: 0;
    //     width: 40px;
    //     height: 6px;
    //     background: #fff;
    //     box-shadow: 0 6px #34495e, 0 12px #fff, 0 18px #34495e, 0 24px #fff;
    //     content: '';
    // }

    .mp-pusher {
        position: relative;
        left: 0;
        height: 100%;
        // height: auto;
    }

    // .mp-menu {
    //     position: absolute; /* we can't use fixed here :( */
    //     top: 0;
    //     left: 0;
    //     z-index: 1;
    //     width: 300px;
    //     height: 100%;
    //     transform: translate3d(-100%, 0, 0);
    // }

    // .mp-level {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: #fff;

    //     transform: translate3d(-100%, 0, 0);
    // }

    /* overlays for pusher and for level that gets covered */
    .mp-pusher::after,
    .mp-level::after,
    .mp-level::before {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        content: '';
        opacity: 0;
    }

    .mp-pusher::after,
    .mp-level::after {
        background: rgba(0,0,0,0.3);

        transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    }

    .mp-level::after {
        z-index: -1;
    }

    .mp-pusher.mp-pushed::after,
    .mp-level.mp-level--overlay::after {
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 10;
        transition: opacity 0.3s;
    }

    .mp-level.mp-level-overlay {
        cursor: pointer;
    }

    .mp-level.mp-level--overlay.mp-level::before {
        width: 100%;
        height: 100%;
        background: transparent;
        opacity: 1;
    }

    .mp-pusher,
    .mp-level {
        transition: all 0.5s;
    }

    /* overlap */
    .mp-overlap .mp-level.mp-level--open {
        box-shadow: 1px 0 2px rgba(0,0,0,0.2);

        transform: translate3d(-40px, 0, 0);
    }

    /* First level */
    .mp-menu > .mp-level,
    .mp-menu > .mp-level.mp-level--open,
    .mp-menu.mp-overlap > .mp-level,
    .mp-menu.mp-overlap > .mp-level.mp-level--open {
        box-shadow: none;
        transform: translate3d(0, 0, 0);
    }

    /* cover */
    .mp-cover .mp-level.mp-level-open {
        transform: translate3d(0, 0, 0) !important;
    }

    .mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open),
    .mp-cover .mp-level.mp-level-open > div > ul > li > .mp-level:not(.mp-level-open),
    .mp-cover .mp-level.mp-level-open > div > div > ul > li > .mp-level:not(.mp-level-open),
    {
        transform: translate3d(-100%, 0, 0);
    }

    /* content style */
    .mp-menu ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    // .mp-level-heading  {
    //     margin: 0;
    //     padding: $bsu-s;
    //     // color: rgba(0,0,0,0.4);
    //     // text-shadow: 0 0 1px rgba(0,0,0,0.1);
    //     font-weight: 600;
    //     @include inuit-font-size(18px, false);
    // }

    // .mp-menu.mp-overlap h2::before {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     margin-right: 8px;
    //     // font-size: 75%;
    //     line-height: 1.8;
    //     opacity: 0;

    //     transition: opacity 0.3s, transform 0.1s 0.3s;

    //     transform: translateX(-100%);
    // }

    // .mp-menu.mp-cover h2 {
    //     text-transform: uppercase;
    //     font-weight: 700;
    //     letter-spacing: 1px;
    //     // font-size: 1em;
    // }

    // .mp-overlap .mp-level.mp-level-overlay > h2::before {
    //     opacity: 1;

    //     transition: transform 0.3s, opacity 0.3s;

    //     transform: translateX(0);
    // }

    .mp-menu ul li > a {
        display: block;

        padding: $bsu-s;
        outline: none;

        // box-shadow: inset 0 -1px rgba(0,0,0,0.2);
        // text-shadow: 0 0 1px rgba(255,255,255,0.1);
        // font-size: 1.4em;
        @include inuit-font-size(18px, false);

        border-bottom: 1px solid $color-blue-20;

        transition: background 0.3s, box-shadow 0.3s;
    }

    // .mp-menu ul li::before {
    //     position: absolute;
    //     left: 10px;
    //     z-index: -1;
    //     color: rgba(0,0,0,0.2);
    //     line-height: 3.5;
    // }

    // .mp-level > ul > li:first-child > a {
    //     box-shadow: inset 0 -1px rgba(0,0,0,0.2), inset 0 1px rgba(0,0,0,0.2);
    // }

    // .mp-menu ul li a:hover,
    // .mp-level > ul > li:first-child > a:hover {
    //     background: #fff;
    //     box-shadow: inset 0 -1px rgba(0,0,0,0);
    // }

    // .mp-menu .mp-level.mp-level-overlay > ul > li > a,
    // .mp-level.mp-level-overlay > ul > li:first-child > a {
    //     box-shadow: inset 0 -1px rgba(0,0,0,0);
    // }

    // .mp-level > ul > li:first-child > a:hover,
    // .mp-level.mp-level-overlay > ul > li:first-child > a {
    //     box-shadow: inset 0 -1px rgba(0,0,0,0), inset 0 1px rgba(0,0,0,0);
    // } /* seems like Chrome 34.0.1847.131 needs the second shadow otherwise the transition breaks */

    .mp-back {

        background: $color-blue-negative;
        color: alphaize(rgba($color-blue-positive, 0.5)) !important;
        outline: none;

        font-weight: 600;
        display: inline-block;
        width: 80%;

        @include inuit-font-size(14px, 1.9);
        padding: $bsu-s;
        position: relative;

        transition: background 0.3s;

        &:before {
            content: "";

            position: relative;
            top: 1px;

            display: inline-block;
            width: 0;
            height: 0;
            margin-right: 7px;


            border: 6px solid $color-blue-50;

            border-bottom-color: transparent;
            border-top-color: transparent;
            border-left-width: 0;
        }
    }

    .mp-close {
        position: relative;


        display: inline-block;
        width: 20%;
        padding: $bsu-s;
        font-weight: 600;
        color: alphaize(rgba($color-blue-positive, 0.5)) !important;
        background: alphaize(rgba($color-blue-positive, 0.2));;

        @include inuit-font-size(14px, false);
        line-height: 26px;

        text-align: center;

        transition: background 0.3s;

        float: right;
    }

    // .mp-back::after {
    //     font-family: 'linecons';
    //     position: absolute;
    //     content: "\e037";
    //     right: 10px;
    //     font-size: 1.3em;
    //     color: rgba(0,0,0,0.3);
    // }

    .mp-menu .mp-level.mp-level--overlay > .mp-back,
    .mp-menu .mp-level.mp-level--overlay > .mp-back::after {
        background: transparent;
        box-shadow: none;
        color: transparent;
    }

    /* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
    /* We'll show the first level only */
    .no-csstransforms3d .mp-pusher {
         //.no-js .mp-pusher {
        // padding-left: 300px;
    }

    .no-csstransforms3d .mp-menu .mp-level {
    // .no-js .mp-menu .mp-level {
        display: none;
    }

    .no-csstransforms3d .mp-menu > .mp-level {
    // .no-js .mp-menu > .mp-level {
        display: block;
}

}
