/**


**/

$inuit-base-font-size:          16px;
$inuit-base-line-height:        28px;

$base-font-size:    $inuit-base-font-size;
$base-line-height:  $inuit-base-line-height;

$heading-line-height-factor:            1.4;

$inuit-base-spacing-unit: $inuit-base-line-height;
$inuit-base-spacing-unit--small: $inuit-base-spacing-unit / 2;
$inuit-base-spacing-unit--large: $inuit-base-spacing-unit * 2;

$bsu: $inuit-base-spacing-unit;
$bsu-s: $inuit-base-spacing-unit--small;
$bsu-l: $inuit-base-spacing-unit--large;

$bsu-xs: 0.5*$bsu-s;
$bsu-xl: 2*$bsu-l;

$container-max: 1024px !default;


$inuit-widths-columns-responsive: (
    1,
    2,
    3,
    4,
    5,
    10
);
