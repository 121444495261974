
.sharedaddy ul > li,
.footer .menu > li {
  &:before {
    content: none;
  }

  text-indent: 0;
}


.wp-polls {
  label {
    @extend %break-line;
  }

  ul {
    > li {
      text-indent: 0;
      padding-left: 0;

      &:before{
        content: none;
      }
    }
  }
}


.nf-form-fields-required {
  @extend .box;
  @extend .box--small;
  background: rgba($color-blue-positive, .2);
  margin-bottom: $bsu;
}


input[type=radio]+label:after {
  top: 10px;
}
input[type=checkbox]+ label:after,input[type=checkbox]:checked + label:after  {
  top: 2px;
}
// input[type=checkbox]:checked + label:after {
//   // top: 2px;
//   top: 10px;
// }

.nf-form-cont  {
  .nf-error-msg {
    @include inuit-font-size(12px);
  }

  .nf-field-container {
    margin-bottom: $bsu-s;
  }

  .btn[disabled] {
    background: $color-blue-positive;
    opacity: .75;
  }

}

.list-radio-wrap.list-radio-wrap ul>li input:checked+label:after {
  top: 10px;
}

.listcheckbox-container {
  input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }

  li:before {
    content: none;
  }
}


.textarea-container .nf-field-element  {
  textarea {
      height: 100px;
  }
}

@include media-query(small) {
  .nf-form-cont {
    .one-half, .one-third {
      margin-left: 0 !important;
      width: 100% !important;
    }
  }
}


.sidebar {
  height: 100%;
  height: calc(100% + #{$bsu});

  padding: $bsu-s;
  background: rgba($color-blue-positive, .15);

  margin: #{-$bsu-s} #{-$bsu-s} #{-$bsu-s} 0;

  .widget h3 {
    margin-bottom: $bsu-s;
  }
}


.page-container {
  min-height: 70vh;
}


.owl-gallery {
    // @extend .clearfix;

    padding: $bsu-s;
    background: $color-blue-decent;
    margin-bottom: $bsu;

    .gallery-item {
        margin-bottom: 0;
        // float: left;
    }

}


#cookie-notice {
    box-shadow: 0 0px 3px #98aec3;
}
.cookie-notice-container {
    @extend .clearfix;
    max-width: $container-max;
    text-align: left;

    margin-left: auto;
    margin-right: auto;
}

#cn-notice-text {
    a {
      color: inherit;
        text-decoration: underline;
    }
}

#cookie-notice .cn-button {
    @extend .btn;
    // @extend .btn--brand;
    @extend .btn--full;

    margin-left: 0;
    margin-right: 0;
    margin-top: $bsu-s;
}

@include media-query(lap-and-up) {
    #cn-notice-text {
        float: left;
        max-width: 85%;
    }
    #cookie-notice .cn-button {
        width: auto;
        margin-left: $bsu-s;
        margin-top: $bsu-xs;
    }
}
