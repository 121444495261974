
.media-list {
    display: table;
    width: 100%;

    &--center {
        text-align: center;
    }

    &__item {
        display: inline-block;
        width: auto;

        padding: $bsu-s;

        img {
            width: 100%;
        }

        @include media-query(lap-and-up) {
            display: table-cell;

            padding-top: 0;
            padding-bottom: 0;

            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }

    > li {
        @extend .media-list__item;
    }
}
