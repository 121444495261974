
$color-blue-positive: #095183;
// $color-blue-negative: #004d7d;
$color-blue-gray: #c7d4df;

$color-blue-decent: #f7f7f7;

$color-blue-bright: #009EE3; /** background gradient only **/

$color-red: #cd171a;

$color-brand: $color-blue-positive;
$color-brand-alt: #1257A4;

$color-blue-20: alphaize(rgba($color-blue-positive, 0.2));
$color-blue-50: alphaize(rgba($color-blue-positive, 0.5));

// (#706f6f, 10%);
$color-text: alphaize(rgba(#111, .7));
$color-text-light: alphaize(rgba(#111, .4));
$color-text-dark: alphaize(rgba(#111, .55));
//#9d9d9c;

$color-nav-bg: #085183;
$color-nav-text-active: #24559D;

// $color-link: #;

$color-headings: #1F4A77;


// $color#1257A4
