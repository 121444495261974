
.link-list {

    a {
        display: block;
        transition: all .1s ease-in-out;

        &:hover {
            color: $color-brand-alt;
        }
    }

    > li {
        padding: $bsu-xs;

        > a {
            margin: neg($bsu-xs);
            padding: $bsu-xs;
        }

        @include media-query(lap-and-up) {
            padding-top: halve($bsu-xs);
            padding-bottom: halve($bsu-xs);

            > a {
                margin-top: neg(halve($bsu-xs));
                margin-bottom: neg(halve($bsu-xs));

                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    &--bordered {
        border: 1px solid $color-brand;
        @include media-query(lap-and-up) {
            border: 0;
        }
    }

    &--dot {
        > li {
            > a {
                &:before {
                    content: "\2022";
                    margin-right: 2px;

                    position: relative;

                    margin-left: neg($bsu-xs);
                }
            }
        }
    }
}

