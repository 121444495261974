
.page-header {
  margin-bottom: 0;
  padding: 0;

  position: relative;

  // background-image: url('../images/header_bg.png');
  background-repeat: no-repeat;

  &__inner {
    padding: 0;
    position: relative;
    z-index: 0;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: .1;

      background-image: url('../images/bg-2019.svg');
      background-repeat: no-repeat;
      background-position: right bottom;
      z-index: -1;
    }
  }
  .brand {
    margin-bottom: $bsu;
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .logo {

    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $bsu-xs;

    > img {
      max-width: 90%;

    }
  }

  @include media-query(lap-and-up) {
    // margin-top: 0;
    min-height: 140px;

    &__inner {
      padding: $bsu 0;
      &:after {
        opacity: 1;
      }
    }

    .brand {
      margin-top: $bsu;
      // margin-bottom: 0;
      width: 160px;

      // position: absolute;
      // bottom: $bsu-xs;
      // right: $bsu-s;
    }

    .logo {
      margin-bottom: 0;
      // margin-bottom: $bsu-l - $bsu-s;
      text-align: left;
    }

  }
}
