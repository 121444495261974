

label + .filter-list {
    margin-top: $bsu-s;
}

.filter-list {
    margin-bottom: 0;

    @include inuit-font-size(14px);

    > li {
        @include reset-pseudo();

        display: inline-block;

        margin-bottom: $bsu-xs ;
        padding: 0 $bsu-xs;
        width: auto;

        border: 1px solid $color-blue-50;

        text-indent: 0;

        background: white;

        transition: color .1s ease-out, background .2s ease-out;

        &:hover, &.selected {
            background: $color-blue-50;
            color: white;

            cursor: pointer;

            > a {
                color: inherit;
            }
        }

        a {
            color: inherit;
            text-shadow: none !important;
            background-image: none !important;
        }
    }
}
