
.hamburger {

    $width: 18px;
    $height: 2px;
    $color: $color-brand;

    position: relative;
    top: -4px;
    display: inline-block;
    width: $width;
    height: $height;

    background: $color;

    &:before, &:after {
        content: "";

        position: absolute;
        left: 0;

        display: inline-block;

        width: $width;
        height: $height;
        background: $color;
    }
    &:before {
        top: -6px;
    }
    &:after {
        top: 6px;
    }
}

