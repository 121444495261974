@import "../bower_components/inuit-box/objects.box";

.alert {
    @extend .box;
    @extend .box--small;

    background: $color-blue-positive;
    color: #fff;

}
