/**

**/

@function neg($number) {
    @return -1*$number;
}


@function alphaize($color, $background: white) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);

    @return $solid-color;
}


@function aspect-ratio-percentage($a, $b) {
    @return $b / ($a / 100) * 1%;
}
