/**

**/

.accordion {
    position: relative;

    &__action {
        cursor: pointer;
        margin-bottom: $bsu-s;
        &:hover {
            color: $color-brand-alt;
        }
    }

    &__body {
        max-height: 0;
        overflow: hidden;
        padding: 0;

        transition: max-height .2s ease-in-out, opacity .3s ease-in-out;
    }

    &--indicator {
        .accordion__action {
            &:after {
                content: "\002b";
                position: absolute;
                right: 0;

                transition: .3s transform ease-in-out;
            }
        }
        &.accordion--active {
            .accordion__action {
                &:after {
                    content: "\2212";
                    transform: rotate(180deg);
                }
            }
        }
    }

    &--active {

        .accordion__body {
            max-height: 10000px;
            overflow: visible;
        }
    }

    &--positive {
        @extend .accordion--indicator;

        .accordion__action {
            padding: $bsu-s;

            color: white;
            background: $color-blue-50;

            &:after {
                right: $bsu-s;
            }
        }

        .accordion__body {
            padding: 0 $bsu-s;
        }
    }
}

