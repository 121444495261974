
img {
    height: auto;
}

img[width],
img[height] {
    max-width: 100%;
}

video {

    width: 100%    !important;
    height: auto   !important;
}
