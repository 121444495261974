/**

*/

@mixin position-absolute($width: 100%, $height: 100%, $top: 0, $left: 0) {
    position: absolute;
    top: $top;
    left: $left;
    width: $width;
    height: $height;
}

@mixin plus-sign($pseudo: after) {
    &:#{$pseudo} {
        content: "\002b";
        position: absolute;
        right: 0;

        transition: .3s transform ease-in-out;
    }
}
@mixin minus-sign($pseudo: after) {
    &:#{$pseudo} {
        content: "\2212";
        position: absolute;
        right: 0;

        transition: .3s transform ease-in-out;
    }
}


@mixin accordion($action, $body, $active, $icon: false) {
    &#{$active} {
        #{$action} {
            color: $color-brand-alt;

            @if $icon != false {
                &:after {
                    content: "\2212";
                    // position: absolute;
                    // right: 0;
                    transform: rotate(180deg);
                }
            }
        }
        #{$body} {
            max-height: 10000px;
            overflow: visible;
        }
    }

    #{$action} {
        position: relative;

        cursor: pointer;
        @if $icon != false {
            &:after {
                content: "\002b";
                position: absolute;
                right: 0;

                transition: .3s transform ease-in-out;
            }
        }
    }

    #{$body} {
        max-height: 0;
        overflow: hidden;
        padding: 0;
    }
}


@mixin flyout-bigmenu($element, $content) {

    #{$element} {
        position: static;
        overflow: hidden;

        &:hover {
            overflow: visible;
        }
    }

    #{$content} {
        position: absolute;
        top: 100%;
        // top: -1000px;
        left: -9999px;
        z-index: 5;
    }

    #{$element}:hover > #{$content} {
        // top: 100%;
        left: 0;
        width: 100%;
        max-width: $container-max;
        background: white;

    }

}

@mixin visuallyhidden {
    border:0!important;
    clip:rect(0 0 0 0)!important;
    height:1px!important;
    margin:-1px!important;
    overflow:hidden!important;
    padding:0!important;
    position: absolute!important;
    width:1px!important;
}


@mixin clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}


@mixin reset-pseudo() {
    &:before, &:after {
        content: none;
    }
}

@mixin reset-list() {
    @include reset-pseudo();
    padding-left: 0;
    text-indent: 0;
}

@mixin truncate($truncation-boundary){
    max-width:$truncation-boundary;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}

$background-color: #fff !default;
$link-color: $color-brand !default;
$link-color-active: $color-brand-alt !default;
$link-underline-color: $link-color !default;
$link-underline-color-active: $link-color-active !default;
$link-underline-width: 1px !default;
$link-underline-offset: 2px !default;

@mixin underline-shadow($background-color: #fff) {
    text-shadow: -1px -1px 0 $background-color, 1px -1px 0 $background-color, -1px 1px 0 $background-color, 1px 1px 0 $background-color;
}

@mixin underline($color: $link-underline-color, $weight: $link-underline-width, $offset: $link-underline-offset) {
  background-image: linear-gradient(to top, transparent, transparent $offset, $color $offset, $color ($offset + $weight), transparent ($offset + $weight))
}


@mixin center($xy:xy) {
    @if $xy == xy {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translateX(-50%) translateY(-50%);
    }
    @else if $xy == x {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }
    @else if $xy == y {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
    }
}


@mixin defaultinput() {
    padding: 0 $bsu-xs;

    border: 1px solid $color-blue-20;
    border-radius: 0;

    color: $color-brand;
    background: white;

    // @include inuit-font-size(16px);
    line-height: 2;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.1);

    &:focus {
        outline: none;
        box-shadow: 0 0 3px $color-brand,
                    inset 0 1px 1px rgba(0,0,0,.1);
        // border-color: $color-brand;
        // outline: 1px solid $color-blue-50;
        // box-shadow: inset 0 1px 1px rgba($color-blue-20,.75);
        // box-shadow: inset 0 1px 1px $color-;
    }

    &[readonly] {

    }
    &[disabled] {
        background: #f9f9f9;
        color: $color-blue-50;
    }
}

@mixin searchinput() {
    // box-sizing: border-box;

    box-shadow: none;
    border: 0;
    width: 100%;
    // max-width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;

    background-color: white;
    background-position: 95% center;

    line-height: $bsu;
    height: $bsu + 4px; // add padding

    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M18.432%2014.97l-1.285-1.287c.227-.688.354-1.42.354-2.183%200-3.86-3.14-7-7-7s-7%203.14-7%207%203.142%207%207%207c.764%200%201.497-.127%202.185-.354l2.323%202.32.064.055c.6.507%201.348.785%202.104.785%201.726%200%203.13-1.404%203.13-3.13%200-.84-.328-1.63-.924-2.22l-.95-.985zM5.5%2011.5c0-2.757%202.243-5%205-5s5%202.243%205%205-2.243%205-5%205-5-2.243-5-5z%22%20fill%3D%22%237fa6be%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    position: static;

    &:focus {
        box-shadow: none;
    }

}
