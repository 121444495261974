/**

**/

.headline {
    display: block;
    // width: 100%;

    padding-bottom: quarter($bsu-s);
    // margin-bottom: halve($bsu-s);

    color: $color-blue-positive;
    border-bottom: 1px solid $color-blue-positive;

    font-weight: 700;
    // @include inuit-font-size(16px);

    &--soft {
        @extend .headline;

        border-bottom-color: alphaize(rgba($color-blue-positive, 0.5), white);
    }
}
