

// $color-blue-dark: #004d7d;

$color-blue-positive: #005086;
$color-blue-negative: #e9eef2;
$color-blue-gray: #c7d4df;

$color-blue-decent: #8ea8bf;

$color-blue-bright: #dee5ec; /** background gradient only **/

$color-red: #cd171a;
$color-green: green;

$color-brand: $color-blue-positive;
$color-brand-alt: $color-red;

$color-blue-20: alphaize(rgba($color-blue-positive, 0.2));
$color-blue-50: alphaize(rgba($color-blue-positive, 0.5));

$icon-fill: #7fa6be;
// $color-container: #fff;

$inuit-list-ui-border-color:    $color-blue-negative;


$olympia-blue:     rgb(0,122,201);
$olympia-red:      rgb(225,14,73);
$olympia-black:    rgb(0,0,0);
$olympia-yellow:   rgb(255,161,0);
$olympia-green:    rgb(0,155,58);


$rio-green: #007035;
$rio-green-bright: #6BBA43;
$rio-orange: #F66E20;
$rio-orange-bright: #FFC60A;
$rio-blue: #016FAE;
$rio-blue-bright: #00B4E5;


$brand-facebook: #3b5998;
$brand-instagram: #405de6;
$brand-twitter: #55acee;
$brand-youtube: #FF0000;



// $olympia-blue:     #1382C5;
// $olympia-red:      #EB3251;
// $olympia-black:    #231F1F;
// $olympia-yellow:   #F9B041;
// $olympia-green:    #17A452;

$color-tokyo: #03255F;
