
.breadcrumb {
    margin-bottom: $bsu-s;
    padding: 0 $bsu-s;

    background: $color-brand;

    &, a, a:link {
        color: white;
    }

    @include inuit-font-size(13px);

    .breadcrumb_last {
        // color: white;
        font-weight: 700;
    }

    @include truncate(100%);

    @include media-query(lap-and-up) {
        margin-bottom: $bsu;
    }

    &--light {
        padding: 0;
        background: none;

        &, a, a:link {
            color: $color-blue-50;
        }
    }
}

.breadcrumb + .tile--large {
    margin-top: -$bsu-s;

    @include media-query(lap-and-up) {
        margin-top: -$bsu;
    }
}

.breadcrumb + article > .entry-meta {
    // margin-top: -$bsu-s;
}

.entry-title + .breadcrumb {
    margin-bottom: $bsu-s;
}

